:root {
    --featured-project-sizing: 20em;
}

.FeaturedPreview-container, .FeaturedPreview-item-hovered {
    position: relative;
    cursor: pointer;
}


.FeaturedPreview-item:hover::before {
    
    background: rgb(0,0,0);
    background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 10%, rgba(0,0,0,0.5) 100%); 
    height: 100%;
    width: 100%;
    
}



.FeaturedPreview-item, .FeaturedPreview-item-hovered {
    position: relative;
    margin: auto;
    width: 80%;
    display: inline-block;

    border: 2px solid black;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    margin: 1em;
    cursor: pointer;

    width: var(--featured-project-sizing);
    
    transition: filter 0.3s ease; /* Add transition for smooth effect */
    border-radius: 2em;
    overflow: hidden;
    
}

.FeaturedPreview-item:hover, .FeaturedPreview-item-hovered {
    filter: brightness(0.4);
    
}

.FeaturedPreview-item img, .FeaturedPreview-item-hovered img {
    border-radius: 2em 2em 0em 0em;
    transition: transform 0.3s ease; /* Add transition for smooth effect */
    object-fit: cover;
}

  
.FeaturedPreview-item:hover img, .FeaturedPreview-item-hovered img {
    transform: scale(1.1); /* Increase image size by 10% on hover */
    
}

.FeaturedPreview-item > .image-container, .FeaturedPreview-item-hovered > .image-container {
    width: 100%;
    overflow: hidden;
}

.FeaturedPreview-container .hover-content {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}

.FeaturedPreview-text-container {
    width: 100%;
    display: inline-grid;
    /*margin-left: 1em;*/
}

.FeaturedPreview-text-container > h3 {
    margin-bottom: 0em;
    font-size: larger;
    
}

.FeaturedPreview-text-container > p {
    background: var(--background-color);
    color: var(--foreground-color);
    /*rgba(127, 255, 212, 0.75);*/
    padding: 1em;
    
    /*margin-left: -5em; */ /* was here to overlap it*/
    border-radius: 0.2em;
    opacity: 75%;
    -webkit-opacity: 75%;
    margin-top: 0em;
}

.FeaturedPreview-text-container > .FeaturedPreview-tags {
    display: block;
    margin-top: 0em;
    width: 100%;
}


.FeaturedPreview-text-container > .FeaturedPreview-tags > p {
    padding-left: 0.5em;
    padding-right: 0.5em;
    
}


.FeaturedPreview-container  img {
    width: 100%;
    height: var(--featured-project-sizing);
}

.FeaturedPreview-tags > .tag {
    background: var(--background-color);
    opacity: 75%;
    -webkit-opacity: 75%;
    padding: 0.5em;
    margin: 0.5em;
    z-index: 1;
    display: inline-block;
}

.FeaturedPreview-wrapper {

    padding: 3em;
}


.FeaturedPreview-container {
    
    margin: auto;
    /*border: 2px solid black;*/
    /*backdrop-filter: blur(10px);*/
}

@media screen and (min-width: 736px) {
    .FeaturedPreview-container {
        font-size: 1.35em; 
        color: aliceblue;  
    }
    
}


/* */
/* Styles for devices with a maximum width of 767px (typical smartphones) */
@media screen and (max-width: 991px) {
    /* Your mobile styles go here */
    .FeaturedPreview-item {
        display: inline-block;
    }
    .FeaturedPreview-item > img {
        border: 2px solid cadetblue;
        border-radius: 5px 5px 5px 5px;
    }
  }
  
  /* Styles for devices with a width between 768px and 991px (typical tablets) */
  @media screen and (max-width: 991px) {
    /* Your tablet styles go here */
    .FeaturedPreview-item { 
        
        /* Mobile style from here and under*/
    }
  }
  
  /* Styles for devices with a width between 992px and 1199px (larger tablets and small desktops) */
  @media screen and (min-width: 992px) {
    /* Your larger tablet and small desktop styles go here */
    .FeaturedPreview-container {
        font-size: 1.35em; 
        /* pc style from here and above */
    }
  }
  
  /* Styles for devices with a minimum width of 1200px (typical desktops) */
  @media screen and (min-width: 1200px) {
    /* Your desktop styles go here */
    .FeaturedPreview-container {
        font-size: 1.35em; 

    }
  }