
.link-bar-line {
    
    width: 3px;
    /* color: rgb(0, 255, 255); */
    /* mix-blend-mode: difference; */
    background-color: black;
    height: 7em;
    border: 2px var(--navigation-button) solid;
    border-bottom: 0px;
    margin: auto;
    border-radius: 3px 3px 0px 0px;
    outline: 3px solid white;
}



.link-bar {
    position: fixed;
    left: 2em;
    bottom: 0em;
    
    display: inline-grid;
    font-size: 1.3em;
    color: black;
}


.link-bar > a > * {
    color: var(--foreground-color);
    margin-bottom: 1em;
    padding: 0px;
    padding-left: 1px;
    padding-right: 1px;
    background-color: black;
}
