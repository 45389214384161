.rotatorPreview {
    transform: translate(20em, -1em);
    width:100%;
    position: absolute;
    animation-name: retract;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

.rotatorPreview:hover {
    width:100%;
    position: absolute;
    animation-name: extend;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}


.rp-right {
    right: 100%
}

.rp-image {
    border: 5px white solid;
    border-radius: 2em;
    
}

.rp-extender {
    left: 12em;
    top: 2.25em;
    position: absolute;
    /* border: 2px solid white; */
    /* border-radius: 0 1em 1em 0; */
    width: 1em;
    /*clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 25% 100%, 50% 50%, 25% 0%);*/
    clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 26% 24%, 75% 50%, 25% 75%, 25% 100%, 100% 100%, 100% 0%);
    height: 1.5em;
    background: white;
    
    border-radius: 0 1em 1em 0;
}


@keyframes extend {
    from {
        transform: translate(20em, -1em);
    }
    to {  
        transform: translate(25em, -1em);
    }
}

@keyframes retract {
    from {
        transform: translate(25em, -1em);
    }
    to {  
        transform: translate(20em, -1em);
    }
}