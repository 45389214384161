
.ProjectPreview-container {
    
}

.ProjectPreview-item {
    margin: auto;
    width: 80%;
    display: inline-flex;

    border: 2px solid black;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    margin: 1em;
    cursor: pointer;
}

.ProjectPreview-text-container {
    width: 100%;
    display: inline-grid;
    /*margin-left: 1em;*/
}

.ProjectPreview-text-container > p {
    background: var(--background-color);
    color: var(--foreground-color);
    /*rgba(127, 255, 212, 0.75);*/
    padding: 1em;
    
    /*margin-left: -5em; */ /* was here to overlap it*/
    border-radius: 0.2em;
    opacity: 75%;
    -webkit-opacity: 75%;
}

.ProjectPreview-text-container > .ProjectPreview-tags {
    display: block;
    margin-top: 0em;
    width: 100%;
}


.ProjectPreview-text-container > .ProjectPreview-tags > p {
    padding-left: 0.5em;
    padding-right: 0.5em;
    
}


.ProjectPreview-container  img {
    width: 50%;
}

.ProjectPreview-tags > .tag {
    background: var(--background-color);
    opacity: 75%;
    -webkit-opacity: 75%;
    padding: 0.5em;
    margin: 0.5em;
    z-index: 1;
    display: inline-block;
}

.ProjectPreview-wrapper {

    padding: 3em;
}


.ProjectPreview-container {
    width: 75%;
    margin: auto;
    /*border: 2px solid black;*/
    /*backdrop-filter: blur(10px);*/
}

@media screen and (min-width: 736px) {
    .ProjectPreview-container {
        font-size: 1.35em; 
        color: aliceblue;  
    }
    
}


/* */
/* Styles for devices with a maximum width of 767px (typical smartphones) */
@media screen and (max-width: 991px) {
    /* Your mobile styles go here */
    .ProjectPreview-item {
        display: inline-block;
    }
    .ProjectPreview-item > img {
        border: 2px solid cadetblue;
        border-radius: 5px 5px 5px 5px;
    }
  }
  
  /* Styles for devices with a width between 768px and 991px (typical tablets) */
  @media screen and (max-width: 991px) {
    /* Your tablet styles go here */
    .ProjectPreview-item { 
        
        /* Mobile style from here and under*/
    }
  }
  
  /* Styles for devices with a width between 992px and 1199px (larger tablets and small desktops) */
  @media screen and (min-width: 992px) {
    /* Your larger tablet and small desktop styles go here */
    .ProjectPreview-container {
        font-size: 1.35em; 
        /* pc style from here and above */
    }
  }
  
  /* Styles for devices with a minimum width of 1200px (typical desktops) */
  @media screen and (min-width: 1200px) {
    /* Your desktop styles go here */
    .ProjectPreview-container {
        font-size: 1.35em; 

    }
  }