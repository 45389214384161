@import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,600');

:root{
    --background-colour: #1a191d;
}



html {
    font-family: 'Titillium Web' !important;
    scroll-behavior: smooth;
    
    -ms-overflow-style: none; /* IE and Edge */
    /*
    scrollbar-width: none; /* Firefox */
    
}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
    display: none;
}
 
html, body {
    height: 100%;
    background-color: #FBFBFB;
}
.main-content {
    /* use to be 74, now 63 */
    /*top: 63px;*/
    position: relative;
    text-align: center;
    background-color: white;
    /*padding-top: 3em;*/
}

.main-content > h2 {
    margin-top: 0px;
}

.project-container {
    display: inline-block;
    margin:auto;
}

html {
    font-size: 13px;
}

p {
    font-size: 16px;
}

h1 {
    font-size: 2em;
}

h2 {
    font-size: 1.5em;
}

h3 {
    font-size: 1.17em;
}

h4 {
    font-size: 1em;

}

h5 {
    font-size: 0.83em;

}

h6 {
    font-size: 0.67em;
}

a {
    color: cadetblue;
    text-decoration: underline;
}

.title {
    font-size: 5em;
}