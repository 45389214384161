
.footer {
    margin-top: 0em;
    border-top: 1px solid #F0F0F0;
    background-color: #FBFBFB;
    padding-bottom: 1.5em;
    bottom: 0px;
}

.footer > .icons {
    font-size: 20px;
}

.footer > .icons > * {
    margin:0.3em;

}

.footer > .icons > a:link {
    color: black;
}

/* visited link */
.footer > .icons > a:visited {
    color: black;
}

/* mouse over link */
.footer > .icons > a:hover {
    color: #000000AA;
}

/* selected link */
.footer > .icons > a:active {
    color: #000000DD;
}
