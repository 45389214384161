.contact-content > .project {
    margin: 6em !important;
}

.contact-container {
    font-size: 16px;
    background-color: #F0F0F0;
    border-radius: 1em;
    width: 40em;
    margin: auto;
    /*margin-top: 3em;*/
    padding: 3em;
    margin-bottom:5em;
    position: relative;
}

.contact-container::before {
    content: "";
    position: absolute;
    background-color: #CCCCCC;

    margin-top: 19.5em;
    transform: translateX(-50%);

    width:42em;
    height: 100px;
    border-radius: 100%;
    z-index: -1;
    filter: blur(1em);
}

.contact-content {
    height:100%;
}

.contact-messageArea {
    border-radius: 1em;
    margin: 1em;
    background-color: #FAFAFA;
    border-width: 2px;
    border-color: antiquewhite;
    border-top-color: #AAAAAA;
    border-left-color: #AAAAAA;
}

.contact-email {
    margin-top: 0.5em;
    margin-left: -2em;
}

.contact-email > label {
    margin-right: 0.5em;
}

.contact-email > input {
    border-radius: 0.5em;
    border-color: antiquewhite;
}

.contact-name {
    margin-bottom: 0.5em;
    margin-left: -2em;
}

.contact-name > label {
    margin-right: 0.5em;
}

.contact-name > input {
    border-radius: 0.5em;
    border-color: antiquewhite;
}

.contact-button {
    margin-left: 0.5em;
    margin-right: 0.5em;
    padding: 0.4em;
    border-top-color: #AAAAAA;
    border-left-color: #AAAAAA;
    border-radius: 0.2em;
}


.contact-container input:focus{border-bottom:2px solid #78788c}


.contact-container > .row {
    display: inline-flex;
}

.contact-container > .row > .col-left {
    display: inline-grid;
    float: left;
    padding-right: 1em;
    width: 100%;
    left: 0px;
}

.contact-container > .row > .col-right {
    display: inline-grid;
    float: right;
    padding-left: 1em;
    width: 100%;
}

.col-left > input {
    width: 100%;
}

.col-right > input {
    width: 100%;
}

.gridItem {
    display: grid;
    padding-top: 1em;
    width:100%;
}

form {
    width: 25em;
    margin: auto;

}





@media screen and (max-width: 682px) {

    .contact-container {
        width: 100%;
        margin: auto;
        padding-left: 0;
        padding-right: 0;
    }
    .contact-container::before {
        display: none;
    }
}
