

.rotator {
    position: absolute;
    top: 13em;
    left: -28em;
    animation-name: spin;
    animation-duration: 55000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    /* transform: rotate(3deg); */
     /* transform: rotate(0.3rad);/ */
     /* transform: rotate(3grad); */ 
     /* transform: rotate(.03turn);  */

    &:hover {
        animation-play-state: paused;
    }
    
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
