.work-experience-card, .work-experience-card-expanded {
    border: 1px solid #eaeaea;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff; /* Background color added */
    width: 50vw;
    margin: auto;
    margin-top: 1em;
    margin-bottom: 1em;

}

.work-experience-header {
  background-color: darkslateblue; /* #f8f8f8 */
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: white;

  
}



.work-experience-header:hover {
  background-color: rgb(82, 67, 185);
}

.work-experience-header-active {
    background-color: rgb(68, 52, 169);
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: white;
}

.work-experience-header h3, .work-experience-header-active h3 {
  margin: 0;
  font-size: 1.2rem;
}

.work-experience-details {
    background-color: #ffffff;
    border-top: 1px solid #eaeaea;
    font-size: 0.9rem;
    line-height: 1.6;
    color: black;
    overflow: hidden;
    max-height: 0;
    padding-left: 1em;
    padding-right: 1em;

}

.work-experience-details-closing {
    background-color: #ffffff;
    border-top: 1px solid #eaeaea;
    font-size: 0.9rem;
    line-height: 1.6;
    color: black;
    max-height: 0;
    overflow: hidden;
    animation: work-experience-card-close 1s ease forwards;
}

.work-experience-details p {
  margin: 0;
}

.work-experience-details ul {
  margin: 0;
  padding-left: 20px;
}

.work-experience-details li {
  list-style-type: disc;
}

.work-experience-card-expanded > .work-experience-details {
    animation: work-experience-card-open 1s ease forwards;
}
.work-experience-card > .work-experience-details {
    animation: work-experience-card-close 1s ease forwards;
}

@keyframes work-experience-card-open {
    0% {
        max-height: 0em;
    }
    100% {
        max-height: 10em;
    }
    
}

@keyframes work-experience-card-close {
    0% {
        max-height: 10em;
    }
    100% {
        max-height: 0em;
    }
    
}