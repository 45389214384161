
.project-panel {
    background-color: #1a191d;
    padding-top:8em;
    padding-bottom:10em;
    width: 80%;
    margin: auto;
    color:aliceblue;
}


.project-information {
    border-left: 1px solid var(--background-color);
    border-right: 1px solid var(--background-color);
}

.project-panel .split-container img {
    mask-image: linear-gradient(90deg, transparent, 10%, #1a191d);
}

.project-title-container {
    width: fit-content;
    margin: auto;
    padding-bottom: 5em;
}

.project-panel .title {
    color: aliceblue;
    padding-bottom: 0em;
    margin-bottom: 0em;
}

.project-description {
    text-align: left;
} 

.project-tag-style {
    border-left: 1px dotted #3899FF;
    border-top: 1px dotted #3899FF;
    padding: 1em;
    margin: 1em;
    background-color: #33889915;
}

.project-panel .sub-heading {
    padding-top: 0em;
    margin-top: 0em;
    margin: auto;
    color: aliceblue;
    padding-bottom: 8em;
    
    text-decoration: underline;
    text-align: center;
    
}

.split-container {
    max-width: 100%;
    margin: auto;
    display: inline-flex;
}

.split-container > div:first-child {
    width: 50%;
    margin-left: 3em;
    margin-right: 3em;
    margin-top: 2em;

}

.split-container > div:last-child {
    width: 50%;
    display: inline-flex;
}


.content {

}

.project {
    border-radius: 1em;
    border: 2px solid grey;
    background-color: white;
    width: 40em; /*30em*/
    text-align: left;
    margin:auto;
    display: inline-flex;
    overflow: hidden;
    margin: 1em;
}

.project-contents {
    display:inline;
    padding-left: 1em;
    border-left: 1px solid;
}

.project-contents > h3 {
    margin-bottom: 0.15em;
}

.tags {
    height: 1em;
    display: inline-flex;
    margin:0px;
    margin-left: -0.5em;
}

.scale-down-image {
    object-fit: scale-down;
    background-color: cadetblue;
    margin: auto;
}


.project-contents .links > a{
    padding: 0.5em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    border-radius: 1em;
    background-color: #3899FF;
    color: white;
}

@media screen and (max-width: 682px) {
    .project {
        width: 32em;
    }
}

@media screen and (max-width: 450px) {
    .project {
        width: 99%;
        margin-left: 0px;
        margin-right: 0px;
    }
    .scale-down-image {
        width: 50%;
    }
}
