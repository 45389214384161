.about-container {
    /*width: 95%;
    margin: auto;
    */
    padding: 2.5%;
    font-size: 16px;
    padding-top: 5em;


}


.about-container ul {

    list-style: upper-roman;
    padding-left: 0px;
    margin: auto;
    width: 29em;
}

.about-container > .col-right {
    width: 37em;
    display: inline-block;
    padding: inherit;
}
.about-container > .col-left {
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    
}

.headshot {
    border-radius:50%;
}


.focus-image-plain {
    
    margin: 0 auto;
    height: 100%;
    width: auto;
    animation-delay: 0s;
    
}