:root {
    --link-anim-in-time: 0.2s;
    --link-anim-out-time: 0.2s;
    --link-hover-color: #4646466E;
    --link-def-color: #2E2E2E9C; /* was white, currently: 2E2E2E9C */
    --link-border-radius: 10px;
    --background-color: #2e2e2e; /* was white ,then #2e2e2e, now #18252e */
    --foreground-color: white; /* is #a1fab3 */
    --navigation-button: ##3f4f5a; /* is #223542 */
    --navigation-button-clicked: #223542; /* */
    --navigation-button-outline: #2a4150; /* */

    /* Alternative colour styles */

    --background-color-alt: rgb(235, 235, 235);
    --foreground-color-alt: #222222;
    --link-def-color-alt: rgb(200, 200, 200);
    --link-hover-color-alt: rgb(150,150,150);

    --secondary-color: #851468;
}

.nav-bar-card-container {
    position: sticky;
    /*background-color: var(--background-color-alt) !important;*/
    color: var(--foreground-color-alt) !important;
    font-size: 1rem;
    /*border-bottom: 2px solid #f8f8f888;*/
    z-index: 10;
    margin-bottom: 170px;
    
    transform: translateY(-0%);
    
    height: 54px;
    top: 0;
    width: 80%;
    margin: auto;
    border-radius: 2em 2em 0 0;
    
    display: inline-flex;
    
    text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000,
    1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;

}


.nav-bar-card-container::after {
    border: 2px solid white;
    width: 10vw;
    content: " ";
    display: inline-flex;
    height: 0em;
    margin: auto;
    margin-left: 2em;
    
    outline: 1px solid black;

}

.nav-bar-card-container::before {
    border: 2px solid white;
    width: 10vw;
    content: " ";
    display: inline-flex;
    height: 0em;
    margin: auto;
    margin-right: 2em;
    
    outline: 1px solid black;
}

.nav-bar-card-container-expanded {
    position: sticky;
    /*background-color: var(--background-color-alt) !important;*/
    color: var(--foreground-color-alt) !important;
    font-size: 1rem;
    /*border-bottom: 2px solid #f8f8f888;*/
    z-index: 10;
    margin-bottom: 170px;
    
    transform: translateY(-0%);
    
    height: 54px;
    top: 0;
    width: 80%;
    margin: auto;
    border-radius: 2em 2em 0 0;
    display: inline-flex;
    
    text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000,
    1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
}


.nav-bar-card-container-expanded::after {
    border: 2px solid white;
    width: 10vw;
    content: " ";
    display: inline-flex;
    height: 0em;
    margin: auto;
    margin-left: 2em;

    outline: 1px solid black;
}

.nav-bar-card-container-expanded::before {
    border: 2px solid white;
    width: 10vw;
    content: " ";
    display: inline-flex;
    height: 0em;
    margin: auto;
    margin-right: 2em;
    
    outline: 1px solid black;
}

.nav-bar-card-container h1, .nav-bar-card-container-expanded h1 {
    float: left;
    padding-left: 1em;
    font-size: 2.3em;
    margin-top: 0.4em;
    padding-bottom: 0em;
    margin-bottom: 0em;
}

.nav-bar-card-container ul > li, .nav-bar-card-container-expanded ul > li {
    display: inline-block;
    text-align: center;
    padding: 1rem;
    font-size:1.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    /*margin-top: calc(3.3em);*/
    
    /*animation: navigation-load-in ease 2s forwards;*/

    border-bottom: 1px solid;
    padding: 10px;
    box-shadow: 0px 2px;


}

.nav-bar-card-container ul > li:last-child, .nav-bar-card-container-expanded ul > li:last-child {
    padding-right: 1rem;
}

.nav-bar-card-container a, .nav-bar-card-container-expanded a {
    text-decoration: none;
}

.nav-bar-card-container a, .nav-bar-card-container-expanded a {
    color: var(--foreground-color-alt); /* was #000000dd */
    
    color: white;
}

.nav-bar-card-container ul > li:hover, .nav-bar-card-container-expanded ul > li:hover {
    background-color: var(--link-hover-color-alt);
    border-radius: var(--link-border-radius);
    animation: link-fade-in ease var(--link-anim-in-time);
    cursor: pointer;
}

.nav-bar-card-container ul > li, .nav-bar-card-container-expanded ul > li {

    background-color: inherit;
    /* this was the old color style: */
    /*background-color: var(--link-def-color-alt);*/
    border-radius: var(--link-border-radius);
    animation: link-fade-out ease var(--link-anim-out-time);
}


.nav-bar-card-container > .nav-bar-card-mobile, .nav-bar-card-container-expanded > .nav-bar-card-mobile {
    display: none;
}

.nav-bar-card-container > .nav-bar-card-mobile-open, .nav-bar-card-container-expanded > .nav-bar-card-movile-open {
    float:none;
    margin-top: 6.5em;
}

.nav-bar-card-container > .nav-bar-card-mobile-open > li, .nav-bar-card-container-expanded > .nav-bar-card-mobile-open > li{
    display: flow-root;
    font-size: 1rem;
}


@keyframes link-fade-in {
    from {
        background-color: var(--link-def-color-alt);
        border-radius: var(--link-border-radius);
    }
    to {
        background-color: var(--link-hover-color-alt);
        border-radius: var(--link-border-radius);

    }
}

@keyframes link-fade-out {
    from {
        background: var(--link-hover-color-alt);
        
    }
    to {
        background: var(--link-def-color-alt);
    }
}

@media screen and (min-width: 733px){
    
    .nav-bar-card-container ul, .nav-bar-card-container-expanded ul {
        margin-top: 0em;
        
        /*float:right;*/
        /*padding-right: 3.5rem;*/
        padding-left: 0em;

        /*used for the scroll in effect*/
        /*margin-top: -4em;*/
        color: white;
    }
}

@media screen and (max-width: 722px) {
    .nav-bar-card-container > .nav-bar-card-desktop, .nav-bar-card-container-expanded > .nav-bar-card-desktop {
        display:none;
    }
    .nav-bar-card-container > .nav-bar-card-mobile, .nav-bar-card-container-expanded > .nav-bar-card-mobile {
        position: sticky;
        display: flex;
        font-size: 1.5em;
        background-color: inherit;
        /* this was the old color style: */
        /*background-color: var(--link-def-color-alt);*/
        border-radius: var(--link-border-radius);
        animation: link-fade-out ease var(--link-anim-out-time);
        margin: auto;
        color: white;
        border: 0;
        border-bottom: 1px solid;
        padding: 10px;
        box-shadow: 0px 2px;
    
        text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000,
        1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;

    }
    .nav-bar-card-container > .nav-bar-card-mobile:active, .nav-bar-card-container-expanded > .nav-bar-card-mobile:active{
        background-color: var(--navigation-button);
        border-color: var(--navigation-button-outline);
    }

    
    .nav-bar-card-container ul, .nav-bar-card-container-expanded ul {
        margin-top: 4.3em;
        
        /*float:right;*/
        /*padding-right: 3.5rem;*/
        padding-left: 0em;

        /*used for the scroll in effect*/
        /*margin-top: -4em;*/
        color: white;
    }


}


@keyframes navigation-load-in {

    from {
        margin-top: -3em;
        
    }

    to {
        margin-top: 3.3em;
        /*transform: translateY(1em);*/
        

    }
}

/* Potential animatin that opens up and fills the screen right before the loading of the next website starts. */
@keyframes navigation-open {
    from {

    }
    to {

    }
}


/* Potential animatin that opens up and fills the screen right before the loading of the next website starts. */
@keyframes nav-card-expand-animation {
    from {

    }
    to {

    }
}
