.nav-bar-container {
    position: fixed;
    z-index: 1;
    top: 9em;
    left: 0em;
    width: 15em;
    height: 2em;
    background-color: var(--background-color);
    color: var(--foreground-color);
    border-radius: 0 1em 1em 0;
    display: flex;
    border: 2px solid white;
    border-left: none;
    animation: nav-bar-closed-animation 1s forwards 0.3s;

}

.nav-bar-button {
    border-bottom: 1px solid;
    padding: 10px;
    box-shadow: 0px 2px;
}

.nav-bar-button > .nav-bar-button-open, .nav-bar-button-close {
    text-align: right;
    margin:0;
}

#nav-bar-item-container {
    margin-top: 0.4em;
}

#nav-bar-button, #nav-bar-button-container {
    /*margin-top: 0.15em;*/
    /*margin-left: 2.3em;*/
    margin-top: 0.15em;
    margin-left: 0.6em;
}

#nav-bar-button, #nav-bar-button-container:hover {
    cursor:pointer;
}

#nav-bar-item-container {
    padding-right: 4em !important;
    margin-top: 0.2em;
}

.nav-bar-item {
    color: var(--foreground-color);    
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.nav-bar-item:visited {
    color: var(--foreground-color);

}

.nav-bar-button-container-close {
    animation: nav-bar-button-container-close-animation 1s forwards;
}
.nav-bar-button-container-open {
    animation: nav-bar-button-container-open-animation 1s forwards;
}

.nav-bar-container-close {
    animation: nav-bar-closed-animation 1s forwards;
}

.nav-bar-container-open {
    animation: nav-bar-open-animation 1s forwards;
}




@keyframes nav-bar-open-animation {
    0% {
        background-color: var(--foreground-color);
        color: var(--background-color);
        margin-left:-13em;
        border: 2px solid black;
        
    }
    50% {
        color: var(--background-color);
        
    }
    100% {
        background-color:var(--background-color);
        color: var(--foreground-color);
        margin-left:0em;
        border: 2px solid white;
        border-left: none;
    }
}

@keyframes nav-bar-closed-animation {
    0% {
        background-color:var(--background-color);
        color: var(--foreground-color);
        margin-left:0em;
        border: 2px solid white;
    }
    50% {
        color: var(--foreground-color);
       
    }
    100% {
        background-color:var(--foreground-color);
        color: var(--background-color);
        margin-left:-13em;
        border: 2px solid black;
    }
}


@keyframes nav-bar-button-container-open-animation {
    0% {
        transform: rotateY(0deg);
        margin-left:1em;
    } 
    
    100% {
        transform: rotateY(180deg);
        margin-left: 1.5em;
    }
}


@keyframes nav-bar-button-container-close-animation {
    0% {
        transform: rotateY(180deg);
        margin-left: 1.5em;
    } 
    100% {
        transform: rotateY(0deg);
        margin-left: 1em;
    }
}

@keyframes nav-bar-button-close-flip {
}

@keyframes nav-bar-button-open-flip {
}