.ContentBox, .ContentBox-visible {
    display: inline-grid;
    height: 25em; /* 25vw */
    width: 25em; /* 25vw */
    margin: auto;
    border: 5px solid cornflowerblue;
    /* #851456 */
    color: white;
    opacity: 0;

}

.ContentBox > * {
    /*margin: auto;*/
}

.ContentBox-visible {
    animation: contentFadeIn 2s ease forwards;
}

.ContentBox-heading > svg {
    font-size: 5em;
    margin-top: 1vw;
}

.ContentBox-heading > svg::before {
    content: "";
    border: 2px solid white;
}


.ContentBox-heading > svg::after {
    border: 2px solid white;
    width: 10vw;
    content: " ";
    display: inline-flex;
    height: 0em;
    margin: auto;
    margin-left: 2em;
    
    outline: 1px solid black;
}

.ContentBox:first-of-type {
    border-radius: 1em 0 0 1em;
    border-left: 5px solid cornflowerblue;
    border-top: 5px solid cornflowerblue;
    border-bottom: 5px solid cornflowerblue;
    border-right: none;
}


.ContentBox:last-of-type {
    border-radius: 0 1em 1em 0;
    border-left: none;
    border-right: 5px solid cornflowerblue;
    border-top: 5px solid cornflowerblue;
    border-bottom: 5px solid cornflowerblue;
}


@media screen and (max-width: 995px) {

    .ContentBox {
        border-radius: 0 1em 1em 0;
        width: 25em;
    }
    .ContentBox:first-of-type {
        border-radius: 1em 0 0 1em;
        width: 25em;
    }
    
    
    .ContentBox:last-of-type {
        border-radius: 0 0 1em 1em;
        border-left: 5px solid cornflowerblue;
        border-right: 5px solid cornflowerblue;
        border-top: none;
        border-bottom: 5px solid cornflowerblue;
        width: 25em;
    }
}



@media screen and (max-width: 665px) {

    .ContentBox {
        border-radius: 0 0 0 0;
        width: 90%;
    }
    .ContentBox:first-of-type {
        border-radius: 0 0 0 0;
        border-left: 5px solid cornflowerblue;
        border-right: 5px solid cornflowerblue;
        border-top: 5px solid cornflowerblue;
        border-bottom: none;
        width: 90%;
    }
    
    
    .ContentBox:last-of-type {
        border-radius: 0 0 0 0;
        border-left: 5px solid cornflowerblue;
        border-right: 5px solid cornflowerblue;
        border-top: none;
        border-bottom: 5px solid cornflowerblue;
        width: 90%;
    }
}


@keyframes contentFadeIn {
    0% {
        opacity: 0;
        transform: translateY(2em);
    }
    100% {
        opacity: 100;
        
        transform: translateY(0em);
    }
}