/**
 * @Author: Mathew Jenik
 * @Date:   2021-12-02 12:13:46
 * @Last Modified by:   Mathew Jenik
 * @Last Modified time: 2022-04-27 22:02:31
 */
:root {
    --shadow-animation-top: 67%;
}

.page {
    background-color: #1a191d;
}

.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
    font-size: 2rem;
    z-index: 100;
    transition: opacity 1s ease-out;
}

.fade-out {
  opacity: 0;
}

.intro {
    font-size: 2rem;
    /*border-bottom: 2px solid #F8F8FF;*/
    width: fit-content;
    margin: auto;
    /*padding-bottom: 6.346em;*/
    /*padding-bottom: 9.346em;*/
    padding-bottom: 2.346em;
    z-index: 2;
    position: relative;
    pointer-events: none;
}

.spline-overlay {
    width: 100%;
    min-height: 100vh;
    position: absolute;
    z-index: 2;
}

.landing {
    /*
    background-color: green;
    top: 0px;
    height: 100vh;
    */
    /*max-height: calc(100vh - 118px);*/
    /*background-image: url("../../Images/landingImage.jpg");*/
    /*background-image: url("../../Images/wooden-square.jpg");*/
    background-image: linear-gradient(180deg,#00000012 60%, #1a191d 100%), url('../../Images/hero-backdrop.jpg');
    /*#851456*/
    /*#1a191d*/
    /*#851468*/
    background-size: cover;
    min-height: 100vh;
    
}


.landing {
    color: white;
}

.landing h2 {
    padding-top: 1em;
}


#landing-3d {
    min-height: inherit;
    position: absolute;
    left: 0;
    top: 0;
    height: inherit;
    z-index: 2;
}


/* */
.work-exp-section {
    padding-bottom: 2em;
    padding-top: 4em;
    background-color: #1a191d;
    color: white;
}

.work-exp-section > article > h1 {
    margin-top: 0em;
    font-size: 4em;
}

.mobile-nav-expanded {
    background-color: red;
    width: 100vw;
}

article .text-section {
    padding: 1em;
}

.experience-panel {
    min-height: 80vh;
    padding-bottom: 2em;
}
.experience-panel > article > h1 {
    margin-top: -1px;
    padding-top: 2em;
    color: aliceblue;
}

.experience-panel > article > h1::after {
    border-bottom: 10px solid red;
    content: "";
    width: 10em;
    color: red;
    font-weight: bold;
}

.experience-panel {
    background-color: #1a191d;
}

.landing-container {
    height: 92vh;
}

.intro > h2 {
    margin-top: 0px;
    color: white;
}


.tech-stack {
    display: flow-root;
    border-top: 2px solid #F4F4FF;
    border-bottom: 2px solid #F4F4FF;
    background-color: #FDFDFDAA;

}

.tech-stack li{

}

.tech-stack > .row {
    display:inline-flex;
}

.tech-stack >.row> .left > ul {
    float: left;
    padding-right: 3rem;
    padding-left: 0px;
    list-style: none;
    margin-top: 0px;
}

.tech-stack > .row> .right > ul{
    float: right;
    padding-left: 3rem;
    list-style: none;
    margin-top: 0px;
    padding-top:0px;
}

.tech-stack > .center {
    width: 100%;

}

#home-hero {
}

.gol-animation {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;

}

.columns {
    display: inline-flex;
    width: 100%;
}


.columns > .col-left {
    flex: left;
    /*width: 50%;*/
    margin: auto;
}

.columns > .col-right {
    flex: right;
    width:100%;
    margin:auto;
}

.focus-image-container {
    width: 22rem; /* 15rem */
    height: 30rem; /*20rem*/
    position: relative;
    overflow:hidden;
    border-radius: 50%;
    margin:auto;
}
.focus-image {
    
    margin: 0 auto;
    height: 100%;
    width: auto;
    animation-delay: 0s;
    -webkit-animation-delay: 0s;
    display: none;
}

.focus-image-animated {
    display:inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
    -webkit-animation:  profile-spawn ease 2s;
    animation: profile-spawn ease 2s;
    animation-delay: 0s;
    -webkit-animation-delay: 0s;
}

div .profile-picture  {
    position: relative;
}

/*Glowing*/
.profile-picture::before {
    content: "";
    position: absolute;
    background-color: #CCCCCC;
    top: var(--shadow-animation-top);
    margin-top:2em;
    margin-left: -100px;
    
    width:200px;
    height: 100px;
    border-radius: 100%;
    filter: blur(1em);
    display: none;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

.profile-picture-animated::after {
    /* was ::before
    content: "";
    position: absolute;
    background-color: #CCCCCC;
    top: var(--shadow-animation-top);
    margin-top:-4em;
    margin-left: -100px;
    
    width:200px;
    height: 100px;
    border-radius: 100%;
    filter: blur(1em);
    animation: profile-spawn-shadow ease 2s;
    animation-fill-mode: forwards;
    old*/
    
    
    content: "";
    display: inline-flex;
    /* position: relative; */
    background-color: darkslateblue; /* #853568 */
    top: var(--shadow-animation-top);
    margin-top: 1em;
    /* margin-left: -100px; */
    width: 200px;
    height: 100px;
    border-radius: 100%;
    filter: blur(1em);
    -webkit-animation: profile-spawn-shadow ease 2s;
    animation: profile-spawn-shadow ease 2s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

#profile-picture {
    

}

#follow-up-info {
    position: inherit;
    z-index: 1;
}

#follow-up-info {
    position: inherit;
    z-index: 1;
}

#profile-text {
    display: block;
    z-index: 1;
    padding-left: 2em;
    width: fit-content;
    transform: scale(0%);
}

.image-statement {
    margin-bottom: 2em;
}

.profile-text {
    display: none;
    text-align: left;
}

.profile-text-animated {
    -webkit-animation: profile-spawn ease 2s forwards;
    animation: profile-spawn ease 2s forwards;
    text-align: left;
}

.content {
    margin: auto;
    
    width: 100%;
}
.home-content {
    /*width: 80%;*/
    margin:auto;
    padding: 5em;
    font-size: 16px;
    position: relative;
    /*background-color: #851468;*/
    background-color: darkslateblue;
    background-image: linear-gradient(0deg,#00000012 0%, #1a191d 100%);
    color: white;
}


.tech-stack {
    max-height: 0px;
    max-width: 10px;
    /*
    -webkit-animation: t-stack-opening ease 2s;
    animation: t-stack-opening ease 2s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    */
    animation-fill-mode: forwards;
    overflow: hidden;
}

.tech-stack *{
    opacity: 1;
    color: black;
}

.sweeping-text-wrapper {
    text-align: left;
    /*height: 2.68em;*/
}

.sweeping-text-wrapper > h4 {
    opacity: 0;
    /*white-space: nowrap;*/
}

.sweeping-text {
    position: relative;
    top: -8.3vw;
    -webkit-animation: text-sweep infinite 6s;
    -webkit-animation-delay: 2s;
    animation: text-sweep infinite 6s;
    animation-delay: 2s;
    
    width: inherit;

}

.sweeping-text-container {
    text-align: center;
    overflow: hidden;
    width: 100%; /*10em*/
    height: 9vw; /*1.8em 2em*/
    position: relative;
   /* top:-3em;*//*-3em*/
    left: 0em; /*4.7em*/

    opacity: 0.9;

    margin-top: 3em;
    /*box-shadow: 0px 10px 82px 31px rgba(0,0,0,0.18) inset;*/

}

/*
// effects for sweeping text container shadow effect
box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
box-shadow: 0px 10px 82px 31px rgba(0,0,0,0.18) inset;
*/


.sweeping-text-container h4 {
    font-size: 6vw;
    padding: 0;
    
}

.featured-projects {
    padding-bottom: 0em;
    
}

.featured-projects-gradient {
    
    background-image: linear-gradient(180deg,#00000012 60%, #1a191d 100%), linear-gradient(0deg,#00000012 90%, #483d8b 100%);
    min-height: 100vh;
}

.featured-projects h1 {
    margin: 0;
    padding: 1em;
    font-size: 4em;
}

/* Scroll Lock section */

.snap {
    scroll-snap-align: start;
    
}


html {
    
    /*scroll-snap-type: y mandatory; /* proximity */ /* mandatory */
    overflow-y: scroll;
    height:100vh;
    
}



/* End of scroll lock section */

.titles {
    padding-top: 3em;
    margin-bottom: 4em;
    color: var(--foreground-color);
}

.fixed-background {
    
    background-image: url('../../Assets/pexels-pixabay-302220.jpg');
    min-height: 100vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: var(--foreground-color);
}

.project-preview-container {
    padding-top: 1em;
    padding-bottom: 1em;
    margin:auto;
}


.projects {

    width: 75%;
    margin: auto;
    /*border: 2px solid black;
    backdrop-filter: blur(10px);
    */
}



/* MODAL POPUP */
.modal-popup-content {
    text-align: center;
}


.modal-popup-content > .key-features p {
    border: 2px solid cornflowerblue;
    border-radius: 1em;
    display: inline-flex;
    margin: 1em;
    padding: 1em;
}


/* SCROLL DOWN ANIMATION */

.scroll-down-animation {
    position: absolute;
    top: 80vh;
    width: 100%;
    margin: auto;
    left: 0;
}

.chevron {
    position: absolute;
    width: 28px;
    height: 8px;
    margin-top: 20px;
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;
    left: calc(50% - (28px/2));
}

.chevron:first-child {
    animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
    animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
    content: ' ';
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background: #fff;
}

.chevron:before {
    left: 0;
    transform: skew(0deg, 30deg);
}

.chevron:after {
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg);
}

@keyframes move {
    25% {
        opacity: 1;

    }
    33% {
        opacity: 1;
        transform: translateY(30px);
    }
    67% {
        opacity: 1;
        transform: translateY(40px);
    }
    100% {
        opacity: 0;
        transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }
}

.text-pulse {
    display: block;


    font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: .25;
    animation: pulse 2s linear alternate infinite;
    padding-bottom: 40px;
    margin-top: 10px;
}

@keyframes pulse {
    to {
        opacity: 1;
    }
}


@media screen and (max-width: 682px) {

    h1 {
        font-size: 46px;
    }

    :root {
        --shadow-animation-top: 63%;
    }
    .home-content {
        padding: 0px;
    }

    .columns {
        display: block;
    }

    .columns > .col-left {
        padding-top: 2em;
    }

    #profile-text {
        transform: scale(100%);
        padding-left: 0;
        text-align: center;
    }

    #profile-text > h1 {
        text-align: center;
    }
    #profile-text > i {
        text-align: center;
    }
}
/* 736px */
@media screen and (max-width: 736px) {
            

    .intro {
        position: absolute;
        width: 100%;
        transform: translateY(3em);
        font-size: 2em;

    }
    .nav-bar-card-mobile-open {
        display: flex !important;
        position: fixed !important;
    }

    .scroll-down-animation {
        top: 65vh;
    }

    .focus-image {
        display: block;
    }

    .focus-image-animated {
        display:inline;
        margin: 0 auto;
        height: 100%;
        width: auto;
        animation: none;
    }

    .project-preview {
        display: block;
    }

    /*Glowing*/
    .profile-picture::before {
        content: "";
        position: absolute;
        background-color: #853568;
        top: var(--shadow-animation-top);
        margin-top:2em;
        margin-left: -100px;

        width:200px;
        height: 100px;
        border-radius: 100%;
        filter: blur(1em);
        display: none;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
    }

    .profile-picture::after {
        content: "";
        display: inline-flex;
        /* position: relative; */
        background-color: darkslateblue;
        top: var(--shadow-animation-top);
        margin-top: 1em;
        /* margin-left: -100px; */
        width: 200px;
        height: 100px;
        border-radius: 100%;
        filter: blur(1em);
        animation: none;
    }

    .profile-picture-animated::after {
        content: "";
        display: inline-flex;
        /* position: relative; */
        background-color: darkslateblue;
        top: var(--shadow-animation-top);
        margin-top: 1em;
        /* margin-left: -100px; */
        width: 200px;
        height: 100px;
        border-radius: 100%;
        filter: blur(1em);
        animation: none;
    }

    .profile-text {
        display: block;
    }
    .profile-text-animated {
        animation: none;
    }

    .home-content {
        height:fit-content;
    }
}




@-webkit-keyframes t-stack-opening {
    0% {
        margin-left: 50%;
        max-height: 0px;
        height: 100%;
        max-width: 10px;
    }
    25% {
        margin-left: 0%;
        max-width: 1000px;
        max-height: 0px;
        height: 100%;
    }
    100% {
        max-width: 1000px;
        max-height: 1000px;
        height: 100%;
    }
}

@-webkit-keyframes profile-float {

    0% {
        -webkit-transform: translateY(-5%);
    }
    50% {
        -webkit-transform: translateY(5%);
    }
    100% {
        -webkit-transform: translateY(-5%);
    }

}

@-webkit-keyframes profile-spawn {
    0% {
        -webkit-transform: scale(0%,0%);
    }
    100% {
        -webkit-transform: scale(100%,100%);
    }
}

@-webkit-keyframes profile-spawn-shadow {
    0% {
        top: var(--shadow-animation-top);
        -webkit-transform: scale(0%,0%);
        border-radius: 0%;
        
    }
    35% {

        border-radius: 0%;
    }
    100% {

        border-radius: 100%;
        top: var(--shadow-animation-top);
        -webkit-transform: scale(100%,100%);

    }
}

@-webkit-keyframes profile-spawn {
    0% {
        -webkit-transform: scale(0%,0%);
    }
    100% {
        -webkit-transform: scale(100%,100%);
    }
}

@-webkit-keyframes profile-spawn-shadow {
    0% {
        top: var(--shadow-animation-top);
        -webkit-transform: scale(0%,0%);
        border-radius: 0%;

    }
    35% {

        border-radius: 0%;
    }
    100% {

        border-radius: 100%;
        top: var(--shadow-animation-top);
        -webkit-transform: scale(100%,100%);

    }
}


@-webkit-keyframes text-sweep {
    0% {
        top: -1em;
    }
    25% {
        top: -3.65em;
    }
    50% {
        top: -3.651em;
    }
    75% {
        top: -1em;
    }
    100% {
        top: -1em;
    }
}


@keyframes t-stack-opening {
    from {
        margin-left: 50%;
        max-height: 0px;
        height: 100%;
        max-width: 10px;
    }
    25% {
        margin-left: 0%;
        max-width: 1000px;
        max-height: 0px;
        height: 100%;
    }
    to {
        max-width: 1000px;
        max-height: 1000px;
        height: 100%;
    }
}

@keyframes profile-float {

    0% {
        transform: translateY(-5%);
    }
    50% {
        transform: translateY(5%);
    }
    100% {
        transform: translateY(-5%);
    }

}

@keyframes profile-spawn {
    from {
        -webkit-transform: scale(0%,0%);
        transform: scale(0%,0%);
    }
    to {
        -webkit-transform: scale(100%,100%);
        transform: scale(100%,100%);
    }
}

@keyframes profile-spawn-shadow {
    from {
        top: var(--shadow-animation-top);
        -webkit-transform: scale(0%,0%);
        transform: scale(0%,0%);
        border-radius: 0%;

    }
    35% {

        border-radius: 0%;
    }
    to {

        border-radius: 100%;
        top: var(--shadow-animation-top);
        -webkit-transform: scale(100%,100%);
        transform: scale(100%,100%);

    }
}



@keyframes text-sweep {
    /*
    0% {
        top: -1vw;
    }
    25% {
        top: -3.65vw;
    }
    50% {
        top: -3.651vw;
    }
    75% {
        top: -1vw;
    }
    100% {
        top: -1vw;
    }
    */
    0% {
        top: -8.3vw;
    }
    
    25% {
        top: -24vw;
    }
    50% {
        top: -24.01vw;
    }
    75% {
        top: -8.3vw;
    }
    100% {
        top: -8.3vw;
    }
}

