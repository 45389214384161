@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

.testimonial-container {
    position: relative;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    max-width: 30em;
    border: 2px solid grey;
    border-radius: 4em;
    background-color: aliceblue;
    overflow: hidden;
    margin: 1em;
    padding-bottom: 1em;
}

.testimonial-container > h4 {
    margin-bottom: 0.5em;
}

.checked {
  color: orange;
}

.testimonial-container  .testimonial-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    opacity: 0;
    transition: 0.5s ease;
    object-fit: scale-down;
    background-color: white;
    image-rendering: auto;
}

.testimonial-container:hover .testimonial-image {
    opacity: 1;
}
