body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.maintenance {
  top:50%;
  position: absolute;
  left:50%;
  transform: translate(-50%, -50%);
}

/* MAINTENANCE CSS */
.maintenance > .container {
  margin: auto;

}

.maintenance > body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.maintenance > .container {
    text-align: center;
    max-width: 400px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.maintenance > h1 {
    color: #333333;
}

.maintenance > p {
    color: #666666;
}



.maintenance > .contact-info {
    font-size: 14px;
    color: #999999;
    margin-top: 30px;
}

