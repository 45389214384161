.tag > h4 {
    border-radius: 1em;
    background-color: #389fff;;
    color: #F1F1F1;
    margin: 0px;
    padding-left: 0.4em;
    padding-right: 0.4em;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    margin-left: 0.2em;
    margin-right: 0.2em;
}
