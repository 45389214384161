.project {
    border-radius: 1em;
    border: 2px solid grey;
    background-color: white;
    width: 40em; /*30em*/
    text-align: left;
    margin:auto;
    display: inline-flex;
    overflow: hidden;
    margin: 1em;
}

.project-contents {
    display:inline;
    padding-left: 1em;
    border-left: 1px solid;
}

.project-contents > h3 {
    margin-bottom: 0.15em;
}

.tags {
    height: 1em;
    display: inline-flex;
    margin:0px;
    margin-left: -0.5em;
}

.scale-down-image {
    object-fit: scale-down;
    background-color: cadetblue;
    margin: auto;
}


.project-contents .links > a{
    padding: 0.5em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    border-radius: 1em;
    background-color: #3899FF;
    color: white;
}

@media screen and (max-width: 682px) {
    .project {
        width: 32em;
    }
}

@media screen and (max-width: 450px) {
    .project {
        width: 99%;
        margin-left: 0px;
        margin-right: 0px;
    }
    .scale-down-image {
        width: 50%;
    }
}
