.shufflingText {
    font-family: 'Courier New', monospace;
    font-size: 6vw;
    /*margin-bottom: 2em;*/
    
}

.shufflingText h2 {
    margin-top: 0;
}
