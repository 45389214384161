.services {
    display: inline-flex;
    min-height: 80vh;
}
.hire-container {
    border-radius: 2em;
    border: 2px solid #AAAAAA;
    display:inline-block;
    margin: 1em;
    padding-top: 2em;
    padding-bottom: 2em;
}

.services-container {
    display: inline;
    width: 50%;
}


.services > .left-container {
    width: 50%;
    margin: auto;
}


.left-container > .pms-container {
    border-radius: 2em;
    border: 2px solid #AAAAAA;
    margin: auto;
    margin: 1em;
    margin-top: 2em;

}


@media screen and (max-width: 710px) {
    .services {
        display: inline-block;
    }

    .services > .left-container {
        width:100%;
    }
}
