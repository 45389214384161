.project-tags {
    display: inline-block;
    justify-content: center;
    margin-bottom: 20px;
}

.project-tag {
    padding: 10px 20px;
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f9f9f9;
}

.project-tag.active {
    background-color: #ccc;
}

.project-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.project-list-item {
    margin-bottom: 0px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}