.projects-content {
    padding-top: 5em;
}

.project-contents {
    color: black;
}

.projects-container {
    animation: projectsContainerExpand ease 17s;
    animation-delay: 0.5s;
    overflow: hidden;
    animation-fill-mode: forwards;
    max-height: 0px;
}

h3 {
    margin-top: 3px !important;

}


@keyframes projectsContainerExpand {
    from {
        max-height: 0px;
    }
    to {
        max-height: 2000em;
    }
}

.projects-content > section {
    scroll-snap-align: none !important; 
    
}