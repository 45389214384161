:root {
    --link-anim-in-time: 0.2s;
    --link-anim-out-time: 0.2s;
    --link-hover-color: #4646466E;
    --link-def-color: #2E2E2E9C; /* was white, currently: 2E2E2E9C */
    --link-border-radius: 10px;
    --background-color: #2e2e2e; /* was white ,then #2e2e2e, now #18252e */
    --foreground-color: white; /* is #a1fab3 */
    --navigation-button: ##3f4f5a; /* is #223542 */
    --navigation-button-clicked: #223542; /* */
    --navigation-button-outline: #2a4150; /* */

    /* Alternative colour styles */

    --background-color-alt: rgb(235, 235, 235);
    --foreground-color-alt: #222222;
    --link-def-color-alt: rgb(200, 200, 200);
    --link-hover-color-alt: rgb(150,150,150);
}

.header {
    /*height: 70px;*/
    width: 100%;
    position: fixed;
    display: inline-block;
    top: 0px;
    background-color: var(--background-color-alt);
    color: var(--foreground-color-alt) !important;
    font-size: 1rem;
    border-bottom: 2px solid #f8f8f888;
    z-index: 10;
    margin-bottom: 170px;

    transform: translateX(-50%);
}

.header h1 {
    float: left;
    padding-left: 1em;
    font-size: 2.3em;
    margin-top: 0.4em;
    padding-bottom: 0em;
    margin-bottom: 0em;
}

.header ul {
    margin-top: 0.3em;
    float:right;
    padding-right: 3.5rem;

    /*used for the scroll in effect*/
    /*margin-top: -4em;*/
}

.header ul > li {
    display: inline-block;
    text-align: center;
    padding: 1rem;
    font-size:1.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    /*margin-top: calc(3.3em);*/
    
    /*animation: navigation-load-in ease 2s forwards;*/

    border-bottom: 1px solid;
    padding: 10px;
    box-shadow: 0px 2px;


}

.header ul > li:last-child {
    padding-right: 1rem;
}

.header a {
    text-decoration: none;
}

.header a {
    color: var(--foreground-color-alt); /* was #000000dd */
}

.header ul > li:hover {
    background-color: var(--link-hover-color-alt);
    border-radius: var(--link-border-radius);
    animation: link-fade-in ease var(--link-anim-in-time);
    cursor: pointer;
}

.header ul > li {

    background-color: inherit;
    /* this was the old color style: */
    /*background-color: var(--link-def-color-alt);*/
    border-radius: var(--link-border-radius);
    animation: link-fade-out ease var(--link-anim-out-time);
}


.header > .navigation-mobile {
    display: none;
}

.header > .navigation-mobile-open {
    float:none;
    margin-top: 6.5em;
}

.header > .navigation-mobile-open > li {
    display: flow-root;
}


@keyframes link-fade-in {
    from {
        background-color: var(--link-def-color-alt);
        border-radius: var(--link-border-radius);
    }
    to {
        background-color: var(--link-hover-color-alt);
        border-radius: var(--link-border-radius);

    }
}

@keyframes link-fade-out {
    from {
        background: var(--link-hover-color-alt);
        
    }
    to {
        background: var(--link-def-color-alt);
    }
}



@media screen and (max-width: 722px) {
    .header > .navigation-desktop {
        display:none;
    }
    .header > .navigation-mobile {
        position: absolute;
        display: flex;
        top: 1.55em;
        right: 2em;
        background-color: var(--navigation-button);
        border-color: var(--navigation-button-outline);
        padding: 0.3em;
        font-size: 1.5em;
        transform: translate(0%, -40%);

    }
    .header > .navigation-mobile:active {
        background-color: var(--navigation-button);
        border-color: var(--navigation-button-outline);
    }

}


@keyframes navigation-load-in {

    from {
        margin-top: -3em;
        
    }

    to {
        margin-top: 3.3em;
        /*transform: translateY(1em);*/
        

    }
}

/* Potential animatin that opens up and fills the screen right before the loading of the next website starts. */
@keyframes navigation-open {
    from {

    }
    to {

    }
}
