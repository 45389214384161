
.popupModal-component {
    display: inline-grid;
}

.popupModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 1em 1em 10px 0;
    padding: 1em;
    overflow-y: auto;
    overflow: scroll;
    max-height: 90%;

    scrollbar-width:auto;
}